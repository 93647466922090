import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { contentActions } from 'actions'

import { withRouter, Route, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'


//import ContentStore from './stores/ContentStore'
//import ContentStoreActions from './actions/ContentStoreActions'
import Header from './components/header'
import Page from './components/page'
import Overlay from './components/content/overlay'
import Footer from './components/footer'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';

import CookieOverlay from './components/CookieOverlay'
import { useState } from 'react'

const trackingId = 'UA-46258608-1';

const tagManagerArgs = {
    gtmId: 'GTM-NDL7Z52'
}

/*
TagManager.initialize(tagManagerArgs)
*/

function App({ location, getConfig, resize, language, setLanguage, cookies }) {
    const [allowGA, setAllowGA] = useState(cookies.stats)
    const [allowGTM, setAllowGTM] = useState(cookies.marketing)

    const resizeTimeout = useRef(null)

    const test = () => {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = setTimeout(resize, 100);
    }

    useEffect(() => {

        getConfig();

        window.addEventListener('resize', test)

        return () => {
            window.removeEventListener('resize', test)
        }
    }, [])

    useEffect(() => {

        let languageNew = location.pathname.split('/')[1] === 'en' ? 'en' : 'de'

        //console.log(languageNew, language)

        if (languageNew !== language) {
            setLanguage(languageNew)
        }


    }, [location])

    useEffect(() => {

        if (cookies.stats) {
            setAllowGA(true)
        }

        if (cookies.marketing) {
            setAllowGTM(true)
        }


    }, [cookies])

    /*
    useEffect(() => {

        if(allowGA) {
            console.log('Allow GA')
            ReactGA.initialize(trackingId)
        }

    }, [allowGA])
    */

    useEffect(() => {

        if (allowGTM) {
            console.log('Allow GTM')
            TagManager.initialize(tagManagerArgs)
        }

    }, [allowGTM])


    useEffect(() => {

        if (allowGA) {
            console.log('track GA pageview');
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

    }, [allowGA, location])

    return (
        <>
            <CookieOverlay />
        </>
    )


}

const mapStateToProps = (state, props) => {
    const { language, cookies } = state.content

    return {
        language,
        cookies
    }
}

const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(contentActions.getConfig()),
    resize: () => dispatch(contentActions.resize()),
    setLanguage: language => dispatch(contentActions.setLanguage(language)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))
