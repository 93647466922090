import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { contentActions } from '../../actions'

import HeaderBig from '../content/header'
import HeaderProject from '../content/headerProject'

import TeaserList from '../content/teaserList'
import TextImage from '../content/TextImage'
import MultiGallery from '../content/MultiGallery'
import Gallery from '../content/Gallery'
import Text from '../content/Text'
import PdfForm from '../content/pdfForm'
import Location from '../content/Location'
import Team from '../content/Team'
import Images from '../content/images'
import Contact from '../content/contact'
import NextProject from '../content/nextProject'

import TeaserCategory from '../content/teaserCategory'
import Logos from '../content/logos'
import ContactTeaser from '../content/contactTeaser'
import Media from '../content/media'
import Text2 from '../content/text2'
import TextImage2 from '../content/textImage2'

import Loading from '../loading'
import { CSSTransition } from 'react-transition-group'


const components = {
    teaserList: TeaserList,
    textImage: TextImage,
    multiGallery: MultiGallery,
    gallery: Gallery,
    text: Text,
    pdfForm: PdfForm,
    location: Location,
	team: Team,
	images: Images,
	contact: Contact,
	nextProject: NextProject,
	teaserCategory: TeaserCategory,
	logos: Logos,
	contactTeaser: ContactTeaser,
	media: Media,
	text2: Text2,
	textImage2: TextImage2
}


function Page({location, data, getPage, style, language, cookies}) {
	const [ headerText, setHeaderText ] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0)

		if(!data) { 

			let path = location.pathname

			if(location.search) path+= location.search; 

			//console.log('load now')
			getPage(path)
		}	
	}, [])
	
	useEffect(() => {
		if(data) { 
			document.title = data.title.replace(/&#8217;/g, "’").replace(/&#038;/g, "&").replace(/&#8211;/g, "–") + ' – 747 Studios';
		}		
	}, [data])

	useEffect(() => {
		if(data) {
			//ReactGA.initialize('UA-46258608-1');
			//ReactGA.pageview(location.pathname + location.search);

			//console.log('track' + location.pathname + data.title)

			//console.log(window.dataLayer)
			
			/*
			window.dataLayer.push({
				event: 'pageview',
				page: {
				  url: location.pathname + location.search,
				  title: data.title
				}
			  });
			*/
		}


		
	}, [location, data])
	
	/*
 	updateData(props, state) {
 		if(this.url !== props.location.pathname) {
 			this.url = props.location.pathname
 			//ContentStoreActions.getContent(props.location.pathname)
 			window.scrollTo(0, 0)

 			if(window.location.hostname!=='localhost') {
				//ReactGA.initialize('UA-46258608-1');
				//ReactGA.pageview(props.location.pathname + props.location.search);
			}
 		}
 	}

	componentDidMount() {
		this.updateData(this.props, this.state)
 	}

 	componentWillUpdate(nextProps, nextState) {
 		this.updateData(nextProps, nextState)

 		let data = nextState.content[encodeURIComponent(this.props.location.pathname)]

 		if(data) {
			document.title = data.title.replace(/&#038;/g, "&") + ' - 747 Studios';
		}
	}
	*/


	//let data = this.props.content[encodeURIComponent(this.props.location.pathname)],
	//console.log(this.props.location.pathname)

	let header,
	content
	
	if(data) {
		if(data.header && (data.header.type === 'big' || data.header.type === 'standard')) {
			header = <HeaderBig data={data} style={style} headerText={headerText} language={language} cookies={cookies} />
		}
		
		if(data.header && (data.header.type === 'project')) {
			header = <HeaderProject data={data} style={style} />
		}
		
		content = data.content.map((data, idx) => { 

			const Content = components[data.type];
			return <Content key={idx} data={data} style={style} language={language} cookies={cookies} setHeaderText={
				(text) => { this.setState({headerText: text}) }
			} />;
		});
		
	}
	

	return (
		<div className="page">
			{!data &&
				<div className="center main">
					<div className="inner">
						<div className="loading test">
							<Loading language={language} />
						</div>
					</div>
				</div>
			}
			<CSSTransition
				in={!!data}
				classNames="fade2"
				appear={true}
				timeout={500}>
				<div>
					{header}
					{content}
				</div>
			</CSSTransition>	
		</div>
	)
}

const mapStateToProps = (state, props) => {
	const { pages, style, language, cookies } = state.content

	let path = props.location.pathname

	if(props.location.search) path+= props.location.search; 


	const data = pages.find(page => page.path === path) 

	return {
		data,
		style,
		language,
		cookies
	}
}

const mapDispatchToProps = dispatch => ({
	getPage: (url) => dispatch(contentActions.getPage(url)), 
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Page)