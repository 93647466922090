import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {withRouter, NavLink} from 'react-router-dom'
//import ContentStore from '../stores/ContentStore'
import IconSocial from 'components/icon/IconSocial'
import Icon from 'components/icon/Icon'
import styles from './Footer.module.sass'

function Footer({location, style, language, config}) {
	let intervalId = useRef(null)
	const [ topState, setTopState ] = useState({show: false, bottom: false})

	const scrollStep = () => {
    	if (window.pageYOffset === 0) {
        	clearInterval(intervalId);
    	}
    	window.scroll(0, window.pageYOffset - 150);
	}
	
  	const scrollToTop = () => {
    	intervalId = setInterval(scrollStep, 16.66);
	}
	
	const isActive = (path) => {
		if(location.pathname.indexOf(path)!==-1) {
			return true;
		}
	}

	const resizeTimeout = useRef(null)

    const test = () => {
		clearTimeout(resizeTimeout.current);

		setTopState({show: false, bottom: false})

		let documentHeight = document.documentElement.scrollHeight;
		const scrollTop = window.scrollY
		const bottom = scrollTop + window.innerHeight + 1 >= documentHeight

		if(bottom) {
			setTopState({show: true, bottom})		
		}
		
		resizeTimeout.current = setTimeout(() => {

			//console.log(scrollTop, window.innerHeight, documentHeight)

			if(scrollTop>300) {
				setTopState({show: true, bottom})
			}
			

		}, 2000);
    }

    useEffect(() => {
        window.addEventListener('scroll', test)     

		return () => {
            window.removeEventListener('scroll', test)
        }
    }, [])

	let navigation1

	switch(language) {
		case 'en':

			navigation1 = [
				{
					'path': '/en/jobs',
					'text': 'Jobs'
				},
				{
					'path': '/en/imprint',
					'text': 'Imprint'
				},
				{
					'path': '/en/data-protection',
					'text': 'Data protection'
				},
				{
					'path': '/en/t-c',
					'text': 'T&C'
				}
			]

			break;

		case 'de':
		default:

			navigation1 = [
				{
					'path': '/jobs',
					'text': 'Jobs'
				},
				{
					'path': '/impressum',
					'text': 'Imprint'
				},
				{
					'path': '/datenschutz',
					'text': 'Datenschutz'
				},
				{
					'path': '/agb',
					'text': 'AGB'
				}
			]

			break;
	}

	let btns = navigation1.map((entry, idx) => {
		return  <li key={idx}><NavLink
		to={entry.path}
		activeClassName="selected"
		isActive={() => isActive(entry.path)}>
		<span>{entry.text}</span></NavLink></li>
	})

	let topClasses = []
	topState.show && topClasses.push('show')
	topState.bottom && topClasses.push('bottom')

	let styleFooter = {}

	if(config.colorFooter) {
		styleFooter = { background: config.colorFooter }
	}

	return (
		<div className={styles.footer} style={styleFooter}>
			<div className="center main">
				<div className="inner">
					<div className="clear">
						<div className="text">
							<div className="table">
								<div className="col">
									747 Studios GmbH<br /> 
Ruhrstrasse 49<br />
22761 Hamburg – Germany<br /><br />
<a href="tel:+4940696323400">+49 (0) 40 - 6963 23 40 0</a><br /><br />

<a href="mailto:contact@747studios.de">contact@747studios.de</a>
								</div>
								<div className="col">
								747 STUDIOS GMBH<br />
								Beerenweg 1<br />
								22761 HAMBURG – GERMANY<br /><br />

<a href="tel:+4940696323500">+49 (0) 40 - 6963 23 50 0</a>
								</div>
							</div>
						</div>
						<div className="navigation social">
							<a href="https://www.behance.net/747Studios" target="blank"><IconSocial type="Behance" /></a>
							<a href="https://www.linkedin.com/company/747studios" target="blank"><IconSocial type="linkedin" /></a>
							<a href="https://www.instagram.com/747studios/" target="blank"><IconSocial type="instagram" /></a>
						</div>

						<div className="navigation main">
							<ul>
								{btns}
							</ul>
						</div>
						
						<span
							className={'icon main top ' + topClasses.join(' ')}
							style={{width: style.icon.width, height: style.icon.width}}
							onClick={() => { scrollToTop()}}><Icon type="arrow" /></span>                    	
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => {
	const { language, style, config } = state.content

	return {
		language,
		style,
		config
	}
}

const mapDispatchToProps = dispatch => ({

})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer))